import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UploadJobLocations = () => {
  const [file, setFile] = useState(null);
  const [uploadType, setUploadType] = useState('active'); // 'active' or 'prospective'
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    setIsUploading(true); // Show uploading animation
    setUploadComplete(false); // Reset upload complete state

    const formData = new FormData();
    formData.append('file', file);

    try {
      const endpoint = uploadType === 'active' 
        ? '/api/jobs/upload-jobs' 
        : '/api/jobs/upload-prospective-jobs';

      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setUploadComplete(true); // Indicate upload success
      } else {
        console.error('Upload failed:', response.statusText);
        alert('Upload failed. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred during the upload.');
    } finally {
      setIsUploading(false); // Stop uploading animation
    }
  };

  const handleRedirectToMap = () => {
    navigate('/map'); // Redirect to the map page
  };

  return (
    <div>
      <h2>Upload Job Locations</h2>
      <select 
        value={uploadType} 
        onChange={(e) => setUploadType(e.target.value)} 
        style={{ marginBottom: '1rem' }}
      >
        <option value="active">Active Jobs</option>
        <option value="prospective">Prospective Jobs</option>
      </select>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={isUploading}>
        {isUploading ? 'Uploading...' : 'Upload'}
      </button>

      {isUploading && (
        <div style={{ marginTop: '1rem', color: 'blue' }}>
          <p>Processing your upload... Please wait.</p>
        </div>
      )}

      {uploadComplete && (
        <div style={{ marginTop: '1rem' }}>
          <p style={{ color: 'green' }}>Upload complete!</p>
          <button onClick={handleRedirectToMap}>Go to Map</button>
        </div>
      )}
    </div>
  );
};

export default UploadJobLocations;