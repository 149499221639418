import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import LeadsList from './components/LeadsList';
import LeadDetails from './components/LeadDetails';
import Header from './components/Header';
import BulkUpload from './components/BulkUpload';
import DealList from './components/DealList';
import DealDetails from './components/DealDetails';
import UploadJobLocations from './components/UploadJobLocations';
import JobMap from './components/JobMap';

function App() {
  return (
    <Router>
      <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leads" element={<LeadsList />} />
          <Route path="/lead/:id" element={<LeadDetails />} />
          <Route path="/bulk-upload" element={<BulkUpload />} />
          <Route path="/deals" element={<DealList />} />
          <Route path="/deal/:id" element={<DealDetails />} />
          <Route path="/map" element={<JobMap />} />
          <Route path="/upload-jobs" element={<UploadJobLocations />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;