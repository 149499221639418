import React, { useEffect, useState } from 'react';

const formatDate = (date) => {
  if (!date) return 'N/A';
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};

const JobMap = () => {
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [filter, setFilter] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [mapType, setMapType] = useState('active'); // 'active' or 'prospective'

  useEffect(() => {
    // Fetch locations based on map type
    fetch(`/api/jobs/map/${mapType}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setLocations(data);
          setFilteredLocations(data); // Initialize with all locations
        } else {
          console.error('Invalid data format from API');
        }
      })
      .catch((err) => console.error(`Error fetching ${mapType} locations:`, err));
  }, [mapType]);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const existingScript = document.querySelector('#google-maps-script');

      if (!existingScript) {
        const script = document.createElement('script');
        script.id = 'google-maps-script';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
          initializeMap();
        };

        document.body.appendChild(script);
      } else {
        initializeMap();
      }
    };

    const initializeMap = () => {
      if (!window.google) {
        console.error('Google Maps API is not initialized');
        return;
      }

      const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 8,
        center: filteredLocations.length > 0
          ? { lat: filteredLocations[0].lat, lng: filteredLocations[0].lng }
          : { lat: 0, lng: 0 },
      });

      setMap(mapInstance);
      updateMarkers(mapInstance);
    };

    const updateMarkers = (mapInstance) => {
      // Clear existing markers
      markers.forEach(({ marker }) => marker.setMap(null));
    
      // Add new markers
      const newMarkers = filteredLocations.map((location) => {
        const marker = new window.google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: mapInstance,
          title: location.address,
        });
    
        // Define content for infoWindow based on mapType
        const infoWindowContent =
          mapType === 'prospective'
            ? `
              <div>
                <strong>MTS Job No:</strong> ${location.mtsJobNo || 'N/A'}<br>
                <strong>BT Reference Number:</strong> ${location.btReferenceNumber || 'N/A'}<br>
                <strong>Subcontractor:</strong> ${location.subcontractor || 'N/A'}<br>
                <strong>Exchange:</strong> ${location.exchange || 'N/A'}<br>
                <strong>Days Outstanding:</strong> ${location.daysOutstanding || 'N/A'}<br>
                <strong>Date Submitted:</strong> ${formatDate(location.dateSubmitted)}<br>
                <strong>Cost Code:</strong> ${location.costCode || 'N/A'}<br>
                <strong>Reason:</strong> ${location.reason || 'N/A'}<br>
                <strong>Address:</strong> ${location.address || 'N/A'}<br>
              </div>
            `
            : `
              <div>
                <strong>Job:</strong> ${location.job || 'N/A'}<br>
                <strong>Address:</strong> ${location.address || 'N/A'}<br>
                <strong>Exchange:</strong> ${location.exchange || 'N/A'}<br>
                <strong>Service:</strong> ${location.service || 'N/A'}<br>
                <strong>Description:</strong> ${location.description || 'N/A'}<br>
                <strong>Planned Start:</strong> ${formatDate(location.plannedStartDate)}<br>
                <strong>Planned End:</strong> ${formatDate(location.plannedEndDate)}<br>
                <strong>Job Value:</strong> ${location.totalSynthCost || 'N/A'}<br>
              </div>
            `;
    
        const infoWindow = new window.google.maps.InfoWindow({
          content: infoWindowContent,
        });
    
        // Add click event listener to open infoWindow
        marker.addListener('click', () => {
          infoWindow.open(mapInstance, marker);
        });
    
        return { marker, infoWindow };
      });
    
      // Update state with new markers
      setMarkers(newMarkers);
    };

    if (filteredLocations.length > 0) {
      loadGoogleMapsScript();
    }
  }, [filteredLocations]);

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);

    const filtered = locations.filter(
      (location) =>
        location.postcode?.toLowerCase().includes(value) ||
        location.exchange?.toLowerCase().includes(value) ||
        location.job?.toLowerCase().includes(value)
    );

    setFilteredLocations(filtered);

    // Update suggestions dynamically
    const uniqueSuggestions = [
      ...new Set(filtered.flatMap((loc) => [loc.postcode, loc.exchange, loc.job]))
    ];
    setSuggestions(uniqueSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setFilter(suggestion); // Set the selected value in the filter input
    setSuggestions([]); // Clear suggestions to close the dropdown
    const filtered = locations.filter(
      (location) =>
        location.postcode?.toLowerCase().includes(suggestion.toLowerCase()) ||
        location.exchange?.toLowerCase().includes(suggestion.toLowerCase()) ||
        location.job?.toLowerCase().includes(suggestion.toLowerCase())
    );
    setFilteredLocations(filtered);
  };

  const handleLocationClick = (location) => {
    const targetMarker = markers.find(
      (markerObj) =>
        markerObj.marker.getPosition().lat() === location.lat &&
        markerObj.marker.getPosition().lng() === location.lng
    );

    if (targetMarker) {
      // Center map and open info window
      map.setCenter(targetMarker.marker.getPosition());
      map.setZoom(12);
      targetMarker.infoWindow.open(map, targetMarker.marker);
    }
  };

  const handleMapTypeChange = (e) => {
    setMapType(e.target.value); // Update map type
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Left Panel for List of Locations */}
      <div style={{ width: '30%', overflowY: 'scroll', padding: '1rem', borderRight: '1px solid #ddd' }}>
        <h2>Job Locations</h2>
        <select onChange={handleMapTypeChange} value={mapType} style={{ marginBottom: '1rem' }}>
          <option value="active">Active Jobs</option>
          <option value="prospective">Prospective Jobs</option>
        </select>
        <input
          type="text"
          placeholder="Filter by job, postcode, or exchange"
          value={filter}
          onChange={handleFilterChange}
          onBlur={() => setSuggestions([])} // Clear suggestions on blur
          style={{
            width: '100%',
            padding: '0.5rem',
            marginBottom: '1rem',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        />
        {suggestions.length > 0 && (
          <div style={{ border: '1px solid #ddd', borderRadius: '5px', background: '#fff', padding: '0.5rem' }}>
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                style={{ padding: '0.5rem', cursor: 'pointer' }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
        {filteredLocations.map((location, index) => (
          <div
            key={index}
            style={{
              marginBottom: '1rem',
              padding: '1rem',
              border: '1px solid #ddd',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleLocationClick(location)}
          >
            <h4>{location.job}</h4>
            <p>{location.address}</p>
            <p>{location.exchange}</p>
          </div>
        ))}
      </div>

      {/* Right Panel for Map */}
      <div style={{ width: '70%' }}>
        <div id="map" style={{ height: '100%', width: '100%' }}></div>
      </div>
    </div>
  );
};

export default JobMap;